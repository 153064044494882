<template>
    <modal name="file-preview" width="620px" height="auto" @before-open="beforeOpen" @before-close="beforeClose" classes="rounded-md" :clickToClose="false">
        <div class="flex flex-col justify-between min-h-full h-full">
            <header class="flex items-center justify-end p-2">
                <svg @click="onClose()" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x rounded-full p-2 bg-gray-200 text-gray-600 cursor-pointer hover:text-brand-hover">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </header>
            <div class="container flex-grow overflow-y-auto scroll-bar px-4 py-6">
                <div class="flex items-center flex-col">
                    <template v-if="extension === 'application/pdf'"> <iframe class="scroll-bar" :src="file" style="width: 100%; height: 420px" frameborder="0" /> </template>
                    <template v-else-if="isImg">
                        <img :src="file" class="object-contain max-w-full h-auto block m-auto" />
                    </template>
                    <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 m-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                        </svg>
                    </template>
                    <span class="font-semibold text-black text-lg tracking-wide mt-2">{{ fileName }}</span>
                </div>
            </div>
            <footer class="flex items-center justify-end p-4 border-t border-0 border-solid border-gray-200 bg-gray-50">
                <!-- <button class="border-none py-2 px-6 bg-gray-200 cursor-pointer rounded-lg text-sm h-full flex items-center text-brand hover:bg-blue-500 hover:text-white-text" @click="onClose()">Close</button> -->
                <button class="bg-blue-600 cursor-pointer hover:bg-blue-700 border-none rounded-lg text-sm h-full py-2 px-4 flex items-center text-white-text" @click="onDownload()">
                    <font-awesome-icon icon="download" class="w-4 mr-2" />
                    Download
                </button>
            </footer>
        </div>
    </modal>
</template>

<script>
export default {
    name: "file-preview",
    data() {
        return {
            fileName: "",
            extension: "",
            file: null,
        };
    },
    methods: {
        onClose() {
            this.$modal.hide("file-preview");
        },
        beforeOpen({ params }) {
            console.log(params.attachment, "EVENT");
            this.fileName = params.attachment?.src_filename ?? "";
            this.extension = params.attachment?.content_type ?? "";
            this.file = params.attachment?.attachment_path ?? null;
        },
        beforeClose() {
            this.fileName = "";
            this.extension = "";
            this.file = null;
        },
        onDownload() {
            window.open(this.file);
        },
    },
    computed: {
        isImg() {
            return this.extension.includes("image");
        },
    },
};
</script>

<style lang="scss" scoped></style>
